import { SeeAll } from "Components/Buttons/SeeAll";
import { VitaPercent } from "Components/Buttons/VitaPercent";
import { Medicaments } from "Components/Modals/Medicaments";
import { memo, useState } from "react";
import { Link } from "react-router-dom";

export const VitaDiscount = memo(function VitaDiscountComponent({
  offer,
}: any) {
  const [isOpenMedicaments, setIsOpenMedicaments] = useState(false);
  const [medicamentsId, setMedicamentsId] = useState(null);
  const [offerItemName, setItemOffer] = useState("");

  return (
    <div className="pb-[64px]">
      <Medicaments
        open={isOpenMedicaments}
        close={() => {
          setIsOpenMedicaments(false);
          setMedicamentsId(null);
          setItemOffer("");
        }}
        medicamentsId={medicamentsId}
        offerItemName={offerItemName}
      />
      <div className="container">
        <div className="mt-[40px] lg:mt-2">
          <h2 className="text-[40px] lg:text-sloganLgSize font-bold text-blackPrimary-100 leading-[56px] lg:leading-[34px] md:leading-[24px] mb-[24px] lg:mb-[16px] md:text-sloganMdSize">
            {offer?.offer?.title}
          </h2>
          <p className="text-[20px] lg:text-md md:text-rg font-medium text-blackPrimary-60 leading-[30px] md:leading-[21px] mb-[44px] lg:mb-[34px] md:mb-[24px]">
            {offer?.offer?.description}
          </p>
        </div>
        <div className="grid gap-[32px] lg:gap-[22px] md:gap-[12px] grid-cols-4 lg:grid-cols-2 md:grid-cols-1 mb-5">
          {offer?.offerItems?.map(
            (offer: any, i: number) =>
              i < 4 && (
                <Link
                  to={offer?.clinicId ? `/detailed/${offer?.clinicId}` : "#"}
                  key={i}
                >
                  <div className="bg-gray-30 pt-[20px] pb-[24px] rounded-[12px] flex flex-row items-center justify-between px-[24px] gap-[10px]">
                    <img
                      className="w-[48px] h-[48px]"
                      src={offer.imgUrl}
                      alt="offer image"
                    />
                    <div className="flex flex-col gap-[2px] mr-auto">
                      <h4 className="text-rg font-bold leading-[21px] mb-0">
                        {offer.title}
                      </h4>
                      <span
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setIsOpenMedicaments(true);
                          setMedicamentsId(offer.id);
                          setItemOffer(offer?.title);
                        }}
                        className="cursor-pointer text-sm font-demi text-raspberry-100"
                      >
                        იხილე მეტი
                      </span>
                    </div>
                    <VitaPercent percent={offer.discount} icon={false} />
                  </div>
                </Link>
              )
          )}
        </div>
        {offer?.offerItems?.length > 4 && (
          <div className="flex justify-end md:justify-center">
            <Link to={`/offers/${offer.offer?.id}`}>
              <SeeAll />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
});
