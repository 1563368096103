import {useCallback, useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {ThunkDispatch} from "@reduxjs/toolkit";
import {useFormik} from "formik";
import {ToastContainer, toast} from "react-toastify";
import {ReactSVG} from "react-svg";
import {useAppSelector} from "Store/store";
import {fetchMembers} from "Reducers/fetchMembers";
import {useGetUserQuery} from "Reducers/ApiSlice";
import useWindowSize from "Hooks/useWindowSize";
import {useCardPriceQuery} from "Hooks/Queries/useCardPriceQuery";
import {handlePersonalInfoRequest} from "Functions/Checkout/handlePersonalInfoRequest";
import {handleCardRequest} from "Functions/Checkout/handleCardRequest";
import {CustomCheckbox} from "Components/Checkbox/Checkbox";
import {AboutVita} from "Components/PageSections/Checkout/AboutVita";
import {CreateFamilyMember} from "Components/PageSections/Checkout/CreateFamilyMember";
import {PersonalInfo} from "Components/PageSections/Checkout/PersonalInfo";
import {Save} from "Components/Buttons/Save";
import {BankCards} from "Components/PageSections/Checkout/BankCards";
import {MembersModal} from "Components/Modals/User/Members";
import {CreateMember} from "Components/Modals/CreateMember";
import {CongratsModal} from "Components/Modals/Congrats";
import {DeleteModal} from "Components/Modals/Delete";
import {PaymentsModal} from "Components/Modals/Payments";
import personalInfoScheme from "Scheme/PersonalInfoScheme";
import {WrongInfo} from "Components/Messages/Warnings/WrongInfo";
import {BasicBreadcrumbs} from "Components/Breadcrumbs/Breadcrumbs";
import {Link} from "react-router-dom";
import {useHasUserCard} from "Hooks/Queries/User/useHasUserCard";
import {Tooltip} from "@mui/material";
import {SubscriptionInfo} from "Components/Modals/Payments/SubscriptionInfo";
import {WithBalance} from "../../Components/PageSections/Checkout/WithBalance";
import {BalanceModal} from "../../Components/Modals/BalanceModal";
import {useUserBalance} from "../../Hooks/Queries/User/useUserBalance";
import {TextFieldInput} from "../../Components/Inputs/TextField";
import {Promo} from "../../Components/Promo/Promo";

export const scrollToElement = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({behavior: "smooth", block: "start"});
  }
};

export const Checkout = () => {
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const member = useAppSelector((state) => state.familymembers);
  const paymentRef = useRef<HTMLDivElement | null>(null);
  const {width} = useWindowSize();
  const {error} = useGetUserQuery();
  const state = useAppSelector((state) => state.user);
  const members = useAppSelector((state) => state.familymembers);
  const [membersLength, setMembersLength] = useState({
    isRedyToSent: false,
    length: 0,
  });
  const [promoValue, setPromoValue] = useState("");

  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const [isOpenMembersInPrice, setIsOpenMembersInPrice] = useState(false);
  const [checkboxes, setCheckboxes] = useState({
    subscriptionEnabled: true,
    rememberCard: true,
    agreeToTerms: false,
    errors: {
      subscriptionEnabled: false,
      rememberCard: false,
      agreeToTerms: false,
    },
  });
  const [modalStates, setModalStates] = useState({
    createMember: false,
    deleteDialog: false,
    congratsModal: false,
    balance: false,
    memberId: null,
    mobile: {
      familymembers: false,
      transactions: false,
      payments: false,
      details: false,
      subscriptionDetails: false,
    },
  });
  const [paymentMethod, setPaymentMethod] = useState<any>(undefined);
  const {cardPrice} = useCardPriceQuery(
      membersLength.isRedyToSent,
      membersLength.length,
      promoValue
  );
  const {userHasCard} = useHasUserCard(true);

  const {userBalance,refetchBalance} = useUserBalance(true);


  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refetchBalance();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);


  const hasEnoughBalance = (userBalance?.amount ?? 0) >= cardPrice?.currentPayAmount;


  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phone: "",
      personalNumber: "",
      foreignCitizen: false,
    },
    validationSchema: personalInfoScheme,
    onSubmit: (values) => {
      handleFormSubmission(values);
    },
  });

  const handleFormSubmission = (values: any) => {
    if (!checkboxes.agreeToTerms) {
      handleErrors("agreeToTerms", true);
      console.log("aba")

      if(width <= 832){
        scrollToElement("agreeToTerms")
      }

      if (
          checkboxes.subscriptionEnabled &&
          !checkboxes.rememberCard &&
          paymentMethod?.method !== "savedcard"
      ) {
        handleErrors("rememberCard", true);
      }
      return;
    }

    if (paymentMethod === undefined) {
      if (paymentRef.current) {

        paymentRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
      handleMobileModalStates("payments", true);
      toast("აირჩიე გადახდის მეთოდი", {
        type: "error",
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        theme: "light",
      });
      return;
    }

    if (paymentMethod.method === "balance" && !hasEnoughBalance) {

      toast("თქვენ არ გაქვთ საკმარისი ბალანსი, გთხოვთ შეავსოთ", {
        type: "error",
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        theme: "light",
      });
      return;

    }

    setIsLoadingSave(true);
    if (!isLoadingSave) {
      if (checkboxes.subscriptionEnabled) {
        if (checkboxes.rememberCard || paymentMethod?.method === "savedcard") {
          handlePersonalInfoRequest(
              values,
              () =>
                  handleCardRequest(
                      members,
                      checkboxes,
                      setIsLoadingSave,
                      toast,
                      paymentMethod,
                      promoValue
                  ),
              setIsLoadingSave,
              toast
          );
        } else {
          handleErrors("rememberCard", true);
          setIsLoadingSave(false);
        }
        return;
      }

      handlePersonalInfoRequest(
          values,
          () =>
              handleCardRequest(
                  members,
                  checkboxes,
                  setIsLoadingSave,
                  toast,
                  paymentMethod,
                  promoValue
              ),
          setIsLoadingSave,
          toast
      );
    }
  };

  const handleCheckboxes = (key: string, value: boolean) => {
    setCheckboxes((e) => ({
      ...e,
      [key]: value,
    }));
  };

  const handleErrors = (key: string, value: boolean) => {
    setCheckboxes((e) => ({
      ...e,
      errors: {
        ...e.errors,
        [key]: value,
      },
    }));
  };

  const handleMobileModalStates = useCallback((key: string, state: boolean) => {
    setModalStates((prev) => ({
      ...prev,
      mobile: {
        ...prev.mobile,
        [key]: state,
      },
    }));
  }, []);

  const handleModalStates = useCallback((key: string, state: boolean) => {
    setModalStates((prev) => ({...prev, [key]: state}));
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (state.user) {
      formik.setValues({
        firstName: state.user.firstName,
        lastName: state.user.lastName,
        phone: state.user.phone,
        personalNumber: state.user.personalId,
        foreignCitizen:
            state.user.personalId == null
                ? false
                : state.user.personalId?.length !== 11
                    ? true
                    : false,
      });
    }
  }, [state.user, state.isError, state.isLoading, state.isSuccess]);

  useEffect(() => {
    setMembersLength({
      isRedyToSent: true,
      length: members.members?.filter((e: any) => e.isAproved != true)?.length,
    });
  }, [members.members]);

  useEffect(() => {
    dispatch(fetchMembers());
  }, [dispatch]);

  if (error?.isAuthorized === false) {
    if (window) {
      window.location.href = "/";
    }
    return <div></div>;
  }

  if (userHasCard === true) {
    if (window) {
      window.location.href = "/user/card";
    }
    return <div></div>;
  }

  return (
      <div
          className="container max-w-[1392px] xl:max-w-[calc(100%-80px)] xl:w-[100%] md:px-[16px] md:max-w-full mb-[100px]">
        <ToastContainer/>
        <BalanceModal
            open={modalStates.balance}
            close={() => handleModalStates("balance", false)}
        />
        <CreateMember
            open={modalStates.createMember}
            close={() => handleModalStates("createMember", false)}
        />
        <CongratsModal
            open={modalStates.congratsModal}
            close={() => handleModalStates("congratsModal", false)}
        />
        <SubscriptionInfo
            open={modalStates.mobile.subscriptionDetails}
            close={() => handleMobileModalStates("subscriptionDetails", false)}
        />
        <DeleteModal
            open={modalStates.deleteDialog}
            close={() => handleModalStates("deleteDialog", false)}
            text={`ნამდვილად გსურთ (${member?.memberId?.firstName} ${member?.memberId?.lastName}) ოჯახის წევრის წაშლა?`}
        />
        <div className="flex gap-[32px] md:gap-0 items-start lg:flex-col relative">
          {width < 640 && (
              <div className="mb-[20px] flex flex-col gap-4">
                <BasicBreadcrumbs
                    breadcrumbs={[{title: "ბარათის ყიდვა", link: "/"}]}
                />
                <WrongInfo
                    text={`არასწორი ინფორმაციის შეყვანის შემთხვევაში, გთხოვთ დარეკოთ ქოლ ცენტში <span class="text-[#3A6CE8]">0322185577</span>`}
                />
              </div>
          )}
          <div
              className="w-full bg-gray-30 rounded-[12px] p-[44px] md:p-[16px] sm:p-[12px] flex flex-col gap-[40px] md:rounded-b-[0px] md:gap-[0]">
            <AboutVita/>
            <PersonalInfo formik={formik}/>
            {width <= 832 ? (
                <MembersModal
                    open={modalStates.mobile.familymembers}
                    close={() => handleMobileModalStates("familymembers", false)}
                    handleRemoveMember={() => {
                      handleMobileModalStates("familymembers", false);
                      handleModalStates("deleteDialog", true);
                    }}
                    handleCreateMember={() => {
                      handleMobileModalStates("familymembers", false);
                      handleModalStates("createMember", true);
                    }}
                />
            ) : null}
            {width <= 832 ? (
                <PaymentsModal
                    cardPrice={cardPrice}
                    handleModalStates={handleModalStates}
                    open={modalStates.mobile.payments}
                    close={() => handleMobileModalStates("payments", false)}
                    paymentMethod={paymentMethod}
                    setPaymentMethod={setPaymentMethod}
                />
            ) : null}
            {width > 832 ? (
                <CreateFamilyMember handleModalStates={handleModalStates}/>
            ) : (
                <button
                    aria-label="members"
                    onClick={() => handleMobileModalStates("familymembers", true)}
                    className="bg-[#fff] md:mt-[25px] md:mb-[20px] cursor-pointer flex justify-start items-center rounded-[12px] w-full text-rg font-bold text-blackPrimary-100 p-4 gap-2"
                >
                  ოჯახის წევრები
                  <span
                      className="bg-lightRed rounded-[50%] w-[16px] h-[16px] flex justify-center items-center text-[10px] font-demi text-[#fff]">
                {members?.members?.length}
              </span>
                  <ReactSVG
                      className="ml-auto"
                      src="/images/icons/mobilerightarrow.svg"
                  />
                </button>
            )}
            {width > 832 ? (
                <>
                  <BankCards
                      paymentRef={paymentRef}
                      paymentMethod={paymentMethod}
                      setPaymentMethod={setPaymentMethod}
                  />

                  <WithBalance
                      cardPrice={cardPrice}
                      hasEnoughBalance={hasEnoughBalance}
                      paymentMethod={paymentMethod}
                      setPaymentMethod={setPaymentMethod}
                      handleModalStates={handleModalStates}
                  />


                  {/*<div className={"bg-[#FFFFFF] p-[44px] rounded-xl"}>*/}
                  {/* <h2 className="text-[18px] font-bold mb-[29px]">ბალანსით</h2>*/}
                  {/*  <div className={"py-[13px] px-8 bg-gray-30 rounded-xl"}>*/}

                  {/*    <div className={"flex items-center justify-between"}>*/}
                  {/*      <div className={"flex items-center"}>*/}
                  {/*        <ReactSVG src="/images/icons/lariblack.svg" className={"mr-2"}/>*/}
                  {/*        <p className={"text-[#070A1499] text-sm font-demi"}>ბალანსი :</p>*/}
                  {/*        <span className={"text-sm flex items-center font-demi"}>15.00  <ReactSVG*/}
                  {/*            src="/images/icons/lariSmall.svg"/></span>*/}
                  {/*      </div>*/}
                  {/*      <div className={"bg-[#3A6CE81A] rounded-[100px]"}>*/}

                  {/*      </div>*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</div>*/}


                </>

                // https://bakuri.vitaapp.ge:3000/

            ) : (
                <button
                    aria-label="paymentmethod"
                    onClick={() => handleMobileModalStates("payments", true)}
                    className="bg-[#fff] cursor-pointer flex justify-start items-center rounded-[12px] w-full text-rg font-bold text-blackPrimary-100 p-4 gap-2"
                >
                  გადახდის მეთოდები
                  <ReactSVG
                      className="ml-auto"
                      src="/images/icons/mobilerightarrow.svg"
                  />
                </button>
            )}
          </div>
          <div
              className="sticky top-[105px] menuSize:top-[148px] md:top-0 md:relative w-[398px] lg:w-full min-w-[398px] md:min-w-full bg-gray-30 rounded-[12px] md:rounded-t-[0px] p-6 sm:p-[12px]">
            <h2 className="text-md sm:text-rg font-bold text-blackPrimary-100">
              შეკვეთის დეტალები
            </h2>
            <div
                className="flex flex-col gap-[32px] pb-[20px] my-[32px] mb-[20px] border-b-[1px] border-solid border-b-[#E8ECF4]">
              <div
                  onClick={() =>
                      members?.members?.length > 0 &&
                      setIsOpenMembersInPrice(!isOpenMembersInPrice)
                  }
                  className="flex cursor-pointer justify-between h-[24px]">

                <span className="text-rg font-medium text-blackPrimary-100 flex items-center gap-1">
                  ტრანზაქციის ღირებულება
                  {members?.members?.length > 0 && (
                      <ReactSVG
                          className={`${
                              !isOpenMembersInPrice ? "rotate-[180deg]" : "rotate-0"
                          } mt-1`}
                          wrapper="span"
                          src="/images/icons/familyArrow.svg"
                      />
                  )}
                </span>

                <div className={"flex items-center"}>

                  <span className="font-demi text-md text-green-100 flex items-center gap-1">
                    {cardPrice?.currentPayAmount?.toFixed(2)}
                    <ReactSVG src="/images/icons/larigreen.svg"/>
                  </span>

                  {
                      cardPrice?.promoCodeConditions &&
                      <span className="ml-1 font-demi text-[12px] text-blackPrimary-100 flex items-center line-through">
                        {cardPrice?.originalPrice?.toFixed(2)}
                        {/*<ReactSVG src="/images/icons/lariSmall.svg"/>*/}
                      </span>
                  }

                </div>

              </div>
              {isOpenMembersInPrice && (
                  <div className="mt-[-12px] flex flex-col gap-3">
                    <div className="flex justify-between h-[24px]">
                    <span className="text-sm font-medium text-blackPrimary-60">
                      {state?.user?.firstName} {state?.user?.lastName}
                    </span>
                      <span className="text-rg text-blackPrimary-100 font-medium whitespace-nowrap flex items-center">
                        {cardPrice?.currentPersonalPrice}
                        {/*/ (members?.members?.length + 1)*/}
                        <ReactSVG src="/images/icons/lariSmall.svg"/>
                      </span>
                    </div>


                    {members?.members?.map((member: any, index: number) => (
                        <div key={index} className="flex justify-between h-[24px]">
                          <span className="text-sm font-medium text-blackPrimary-60">
                            {member?.firstName} {member?.lastName}
                          </span>
                          <span className="text-rg text-blackPrimary-100 font-medium whitespace-nowrap flex">
                              {parseInt(cardPrice?.originalMonthlyPayment) /
                                  (members?.members?.length + 1)}
                            <ReactSVG src="/images/icons/lariSmall.svg"/>
                            </span>
                        </div>
                    ))}

                  </div>
              )}
              <div className="flex justify-between h-[24px]">
                <span className="text-rg font-medium text-blackPrimary-100">
                  ჯამური ღირებულება
                </span>
                <div className={"flex items-center"}>
                      <span className="font-demi text-md flex items-center gap-1">
                          {cardPrice?.monthlyPayAmount}{" "}
                        <ReactSVG src="/images/icons/lariblack.svg"/>
                    </span>
                  {
                      cardPrice?.promoCodeConditions &&
                      <span className="ml-1 font-demi text-[12px] text-blackPrimary-100 flex items-center line-through">
                        {cardPrice?.originalMonthlyPayment?.toFixed(2)}{" "}
                        {/*<ReactSVG src="/images/icons/lariSmall.svg"/>*/}
                      </span>
                  }
                </div>

              </div>
              <div className="flex items-center justify-between gap-5">
              <span className="font-medium text-rg text-blackPrimary-100">
                თქვენ შეიძენთ ვიტა ბარათის ყოველთვიურ გამოწერას
              </span>
                {width > 640 ? (
                    <Tooltip
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: "#FFFFFF",
                              color: "#000",
                              padding: 2,
                              fontFamily: "avenir-medium",
                              boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
                            },
                          },
                        }}
                        title="მომხმარებელი შეიძენს ვიტა ბარათის ყოველთვიურ გამოწერას. ამისათვის საჭიროა ყიდვის ინფორმაციის მხოლოდ ერთხელ შევსება და ბარათის დამახსოვრება. სისტემა ყოველ 30 კალენდარულ დღეში ავტომატურად ჩამოჭრის ვიტა ბარათის ღირებულებას. ვიტას გამოწერის გაუქმება შესაძლებელია ნებისმიერ დროს მომხმარებლის პროფილიდან."
                        slotProps={{
                          popper: {
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [0, -14],
                                },
                              },
                            ],
                          },
                        }}
                    >
                      <div className="cursor-pointer">
                        <ReactSVG src="/images/icons/info.svg"/>
                      </div>
                    </Tooltip>
                ) : (
                    <div
                        onClick={() =>
                            handleMobileModalStates("subscriptionDetails", true)
                        }
                        className="cursor-pointer"
                    >
                      <ReactSVG src="/images/icons/info.svg"/>
                    </div>
                )}
              </div>
            </div>

            <div className="flex w-full mb-5 pb-5 border-b-[1px] border-solid border-b-[#E8ECF4]">
              <Promo cardPrice={cardPrice} setPromoValue={setPromoValue} promoValue={promoValue}/>
            </div>

            <div className="flex flex-col gap-4">
              <div>
                <CustomCheckbox
                    checked={checkboxes.subscriptionEnabled}
                    onChange={(e: any) => {
                      handleCheckboxes(
                          "subscriptionEnabled",
                          !checkboxes.subscriptionEnabled
                      );
                    }}
                    label="გამოწერის ჩართვა"
                />
              </div>
              {(paymentMethod?.method !== "savedcard" && paymentMethod?.method !== "balance") && (
                  <div>
                    <CustomCheckbox
                        checked={checkboxes.rememberCard}
                        onChange={(e: any) => {
                          handleCheckboxes("rememberCard", !checkboxes.rememberCard);
                          if (e.target.checked == true)
                            handleErrors("rememberCard", false);
                        }}
                        label="ვეთანხმები ბარათის დამახსოვრებას"
                    />
                    {checkboxes.errors.rememberCard && (
                        <div className="font-demi text-raspberry-100 text-[12px]">
                          გამოწერის ჩასართავად, დაეთანხმეთ ბარათის დამახსოვრებას
                        </div>
                    )}
                  </div>
              )}
              <div>
                <CustomCheckbox
                    checked={checkboxes.agreeToTerms}
                    onChange={(e: any) => {
                      handleCheckboxes("agreeToTerms", !checkboxes.agreeToTerms);
                      if (e.target.checked == true)
                        handleErrors("agreeToTerms", false);
                    }}
                    label={
                      <div>
                        <span className={"top-[100px] absolute"} id={"agreeToTerms"}/>
                        ვეთანხმები{" "}
                        <Link
                            className="text-green-100 underline"
                            target="_blank"
                            rel="noopener noreferrer"
                            to={`${process.env.REACT_APP_RULES}`}
                        >
                          წესებს და პირობებს
                        </Link>
                      </div>
                    }
                />
                {checkboxes.errors.agreeToTerms && (
                    <div className="font-demi text-raspberry-100 text-[12px]">
                      დაეთანხმეთ წესებს და პირობებს
                    </div>
                )}
              </div>
            </div>
            <div className="z-20 mt-[32px] md:fixed md:w-full bottom-0 left-0 flex justify-center">
              <Save
                  disabled={isLoadingSave}
                  onClick={() => formik.submitForm()}
                  className="w-full md:w-[calc(100%-32px)] md:mb-3 max-w-full text-[#fff]"
                  variant="green"
                  label="გადახდა"
              />
            </div>
          </div>
        </div>
      </div>
  );
};

