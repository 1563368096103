import {memo, useEffect, useState} from "react";
import {SectionHeader} from "./Layouts/SectionHeader";
import {ReactSVG} from "react-svg";
import useWindowSize from "Hooks/useWindowSize";
import {useUserBankCardsQuery} from "Hooks/Queries/User/useUserBankCardsQuery";
import {bool} from "yup";
import {useHasUserCard} from "../../../Hooks/Queries/User/useHasUserCard";
import {useUserBalance} from "../../../Hooks/Queries/User/useUserBalance";
import user from "../../../Pages/User";

export const WithBalance = memo(function BankCardsComponent({
                                                              setPaymentMethod,
                                                              paymentRef,
                                                              paymentMethod,
                                                              cardPrice,
                                                              hasEnoughBalance,
                                                              isMob = false,
                                                              handleModalStates,
                                                              fromProfile = false,
                                                            }: {
  setPaymentMethod?: any;
  isMob?: boolean;
  paymentRef?: any;
  paymentMethod?: any;
  cardPrice: any;
  hasEnoughBalance: boolean,
  fromProfile?: boolean;
  handleModalStates?: any
}) {

  const {userBalance, refetchBalance} = useUserBalance(true);

  const {width} = useWindowSize();


  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refetchBalance();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);


  return (
      <div ref={paymentRef}>
        <div
            className={`${
                !fromProfile && "p-[44px]"
            } flex flex-col gap-12 bg-[#fff] rounded-[12px] md:p-0 ${isMob ? "mt-6" : "mt-0"}`}>

          <div>
            <h2 className="text-[18px] font-bold mb-4">ბალანსი</h2>
            <div className="bg-gray-30 px-[32px] py-[13px] rounded-[12px] flex flex-col gap-6">
              <div
                  onClick={() =>
                      setPaymentMethod({
                        method: "balance",
                      })
                  }
                  className={"cursor-pointer "}>

                <div className={"flex justify-between items-center"}>
                  <div className={"flex items-center"}>
                    <ReactSVG src="/images/icons/lariblack.svg" className={"mr-2"}/>
                    <p className={"text-[#070A1499] text-sm font-demi"}>ბალანსი :</p>
                    <span className={"text-sm flex items-center font-demi ml-1"}> {userBalance?.amount?.toFixed(2)}
                      <ReactSVG
                          className={""}
                          src="/images/icons/lariSmall.svg"/>
                    </span>
                  </div>

                  <div className={"flex items-center font-medium"}>

                    <button
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          handleModalStates('balance', true)
                        }}
                        className={"mr-2 rounded-[100px] bg-[#3A6CE81A] flex items-center px-4 py-[10px] cursor-pointer"}>
                      <ReactSVG src="/images/icons/add.svg" className={`${isMob ? "" : "mr-2"} `}/>
                      {!isMob && <p className={"text-blue-100 font-demi text-sm"}>
                        შევსება
                      </p>}
                    </button>

                    {paymentMethod?.method == "balance" ? (
                        <button
                            className="w-[20px] h-[20px] bg-[#fff] border-[6px] border-solid border-green-100 rounded-[50%]"/>
                    ) : (
                        <button className="w-[20px] border-[1px] border-[#070A1499] h-[20px] bg-gray-70 rounded-[50%]"/>
                    )}
                  </div>
                </div>

                {!hasEnoughBalance && <span className={"mt-2 text-raspberry-100 text-sm flex items-center"}>
                     <ReactSVG src="/images/icons/warning.svg" className={"mr-2"}/>
                    თქვენ არ გაქვთ საკმარისი ბალანსი, გთხოვთ შეავსოთ</span>}

              </div>
            </div>
          </div>
        </div>
      </div>
  );
});

